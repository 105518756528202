<template>
  <div>
    <v-img v-if="value"  :src="value" :id="id"></v-img>
  </div>
</template>
<script>
import { getFieldId } from "@/js/helper.js";
export default {
  props: ["result", "field", "fieldAttributes", "value"],
  computed: {
    id() {
      return getFieldId(
        this.fieldAttributes.fieldAttrInput,
        this.result,
        this.field
      );
    },
  },
};
</script>